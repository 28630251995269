import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import PropertyMap from '../../../components/property-map/property-map'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import session from '../../../stores/session'
import endpoints from '../../../helpers/endpoints'
import ReactModal from 'react-modal'
import { AddressBase, CouncilTax, CMOSLive, Summary, ValuationOffice, CMOSFormatted } from '../BusinessAddressAlert/shared.jsx'
import { MDS } from '../OccupierTelephoneValidation/sharedComponents'

export default view(function PortfolioSearch() {
    const [summaryData, setSummaryData] = useState({})
    const [selectedMatches, setSelectedMatches] = useState({abp: '', voa: '', ct: ''})
    const [dataIssue, setDataIssue] = useState('')
    const [confirmModal, setConfirmModal] = useState(false)
    const [mdsData, setMdsData] = useState([])
    const [mdsDataReady, setMdsDataReady] = useState(true)
    const [customMDSPostcode, setCustomMDSPostcode] = useState('')

    const [translations, setTranslations] = useState({})
    useEffect(() => {
        request(true).get('/translate?key=HP.')
        .then(e => {
            setTranslations(e.data)
        })
    }, [])
    
    useEffect(() => {
        setSelectedMatches({abp: '', voa: '', ct: ''})
    }, [summaryData.Core_SPID])

    const getMDSData = () => {
        if (!summaryData || !summaryData.Core_SPID) return
        setMdsDataReady(false);
        request(true).get(endpoints.BUSINESS_ADDRESS_ALERTS_MDS_SPID, {
          doesCancel: true,
          params: {
              postcode: customMDSPostcode || summaryData.CF_Postcode || summaryData.CL_Postcode
          }
        }).then(r => {
          setMdsData(r.data)
          setMdsDataReady(true)
        }).catch(e => {
          setMdsDataReady(true)
            console.log(e);
        })
      }

    return (
        <GenericPageContainer
          containerWidth={"95vw"}
          title="Portfolio Summary - Search"
          titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
          titleRight={
            <div>
              <Link className="navigate" to="/portal/portfolio-summary">
                  To Summary
              </Link>
            </div>
          }
        >
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <div style={{display: 'grid', gap: 10}}>
                <Summary translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} setMdsData={setMdsData} getMDSData={getMDSData} setMdsDataReady={setMdsDataReady} generic={true} />
            </div>
            <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
                <CMOSLive translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} generic={true} />
                <AddressBase translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} generic={true} />
                <ValuationOffice translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} generic={true} />
                <CouncilTax translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} generic={true} />
                <CMOSFormatted translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setConfirmModal={setConfirmModal} generic={true} />
            </div>
        </div>
        <MDS data={ mdsData } MDSDataReady={ mdsDataReady } getData={ getMDSData } customPostcode={ customMDSPostcode } setCustomPostcode={ setCustomMDSPostcode } style={{ fontSize: '14px' }} classes={"squish"} SPID={summaryData?.Core_SPID} /> 
        <div style={{minHeight: '40vh', marginBottom: '10em'}}>
          <PropertyMap 
            translations={translations} 
            address={summaryData['CMOS_Address_Single_Line']} 
            meterCoords={summaryData && summaryData.GISX && summaryData.GISY && [parseInt(summaryData.GISX), parseInt(summaryData.GISY)]} 
            cmosMeterRecenter={true} />
        </div>
        </GenericPageContainer>
    )
})
