import axios from 'axios';
import session from '../stores/session';

const cancelTokens = new Map()

export default (authed = false) => {
    let obj = {
        baseURL: sessionStorage.getItem('api-path')
    };

    if (authed) {
        obj.headers = {
            Authorization: 'Bearer ' + sessionStorage.getItem('user-token')
        }
    }
    

    let ax = axios.create(obj);
    
    ax.interceptors.request.use(config => {
        const { url, doesCancel } = config
        
        if (doesCancel && cancelTokens.has(url)) {
            const cancel = cancelTokens.get(url)
            cancel()
            cancelTokens.delete(url)
        }
        
        const source = axios.CancelToken.source()
        config.cancelToken = source.token
        cancelTokens.set(url, () => {
            source.cancel()
            cancelTokens.delete(url)
        })
        
        return config
    }, err => {
        return Promise.reject(err)
    })

    ax.interceptors.response.use(
        res => {
            const { config } = res

            if (config.doesCancel) {
                cancelTokens.delete(config.url)
            }
            
            return res
        },
        err => {
            console.log(err.response);

            if (axios.isCancel(err)) {
                // return Promise.reject({ status: 499, data: "Request cancelled" })
                return new Promise(() => {})
            }
            
            if (err.message === 'Network Error') {
                return Promise.reject({ status: 500, data: 'Network Error' });
            }

            if ((err.response.status === 403 || err.response.status === 401)) {
                console.log('Hit a 401 or 403, resetting session. bad 🗞')
                session.reset();
                // window.alert(401)
            }

            throw err;
        }
    )

    return ax;
}
