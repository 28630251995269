import React, { useCallback, useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactSelect from 'react-select'
import endpoints from '../../../helpers/endpoints'
import request from '../../../helpers/request'
import { GenericTable } from '../VacantCOTAlerts/sharedComponents'
import Button from '../../../components/large-link-button/largeLinkButton'
import ReactModal from 'react-modal'
import hasPermission from '../../../helpers/permissions'
import classCodes from '../../../assets/classification-codes.json'
import { Link, useHistory } from 'react-router-dom';

export const Summary = ({summaryData, setSummaryData, workflow = false, setMdsData, setMdsDataReady, translations = {}, getMDSData, generic=false}) => {
  const history = useHistory();
  const { state } = history.location;
  const [spid, setSpid] = useState(state?.spid)
  const [loadingWorkflow, setLoadingWorkflow] = useState(false)
  const [search, setSearch] = useState(state?.spid)
  const [wSpid, setWSpid] = useState()
  const [sSpid, setSSpid] = useState()
  const [retOutcome, setRetOutcome] = useState('')
  const [wsOutcome, setWsOutcome] = useState('')
  const [wholesalerNotes, setWholesalerNotes] = useState('')
  const [retailerNotes, setRetailerNotes] = useState('')
  const [wsNoteIndex, setWsNoteIndex] =  useState()
  const [retNoteIndex, setRetNoteIndex] =  useState()
  const [prevWsNotes, setPrevWsNotes] =  useState([])
  const [prevRetNotes, setPrevRetNotes] =  useState([])
  const [workflowItems, setWorkflowItems] = useState([])
  const [workflowCounts, setWorkflowCounts] = useState([])
  const [workflowOutcomeSetting, setWorkflowOutcomeSetting] = useState('New')
  const [searchResults, setSearchResults] = useState()
  const [searchIsLoading, setSearchIsLoading] = useState(false)
  const links = {
    'Automated Address Cleanse': '/portal/automated-address-cleanse/search',
    'Invalid Postcode Street': '/portal/invalid-postcode-street/search',
    'UPRN/VOA Address Conflicts': '/portal/invalid-uprn-voa/search',
    'Invalid UPRN/VOA CMOS Reference': '/portal/invalid-uprn-voa-cmos/search',
    'Non-Addressable': '/portal/undeliverable/search'
  }
  const reset = () => {
    setSummaryData({})
    setWholesalerNotes('')
    setRetailerNotes('')
    setWsOutcome('')
    setRetOutcome('')
    setMdsData(false)
    setMdsDataReady(true)
    setPrevWsNotes([])
    setPrevRetNotes([])
    setWsNoteIndex(0)
    setRetNoteIndex(0)
    setSearchResults([])
  }

  const getSummaryData = useCallback((spidSearch, spidType) => {
    setSearch()
    setSummaryData({})
    setRetOutcome('')
    setWsOutcome('')
    setSearchIsLoading(true)
    setSearchResults([])
    request(true).get(generic ? endpoints.PORTFOLIO_SUMMARY_SEARCH_BY_SPID : endpoints.BUSINESS_ADDRESS_ALERTS_SEARCH_BY_SPID, {
      doesCancel: true,
      params: {
        spid: spidSearch || spid,
        spidType: spidType,
      }
    }).then(e => {
      setSummaryData(e.data.customer ?? {})
      setSpid(e.data.customer.Core_SPID)
      if (spidType === "wSpid") {
        setSearch('')
        setSSpid('')
      } else if (spidType === "sSpid") {
        setSearch('')
        setWSpid('')
      } else {
        setWSpid('')
        setSSpid('')
      }
      setWsNoteIndex(e.data.notes?.filter(note => note.Note_Type === "Wholesaler").length)
      setRetNoteIndex(e.data.notes?.filter(note => note.Note_Type === "Retailer").length)
      setPrevWsNotes(e.data.notes?.filter(note => note.Note_Type === "Wholesaler"))
      setPrevRetNotes(e.data.notes?.filter(note => note.Note_Type === "Retailer"))
      setWsOutcome(e.data.customer?.WS_Outcome)
      setRetOutcome(e.data.customer?.Ret_Outcome)
      if (generic) {
        setSearchResults(e.data?.issues)
      }
      setSearchIsLoading(false)
    }).catch(e => {
      reset()
      setSearchIsLoading(false)
      window.alert('Could not find SPID.')
    })
  }, [spid, setSummaryData])

  useEffect(() => {
      window.reloadSummaryData = getSummaryData
  }, [getSummaryData])

  const getWorkflowItems = () => {
    setLoadingWorkflow(true)
    request(true).get(endpoints.BUSINESS_ADDRESS_ALERTS_WF_ITEMS, {
      doesCancel: true,
      params: {
        outcome: workflowOutcomeSetting
      }
    }).then(e => {
      setWorkflowItems(e.data);
      if (e.data.length > 0) {
        setSpid(e.data[_cur && _cur !== -1 ? _cur : 0])
      } else {
        reset()
        setSpid()
        setWSpid()
        setSSpid()
        setSearch()
      }
      setLoadingWorkflow(false);
    }).catch(e => {
      console.log(e);
      window.alert('Could not retrieve data')
      setLoadingWorkflow(false)
    })
  }

  const getWorkflowCounts = () => {
    setSearch()
    setSummaryData({})
    setRetOutcome('')
    setWsOutcome('')
    request(true).get(endpoints.BUSINESS_ADDRESS_ALERTS_WF_COUNTTS).then(r => {
      setWorkflowCounts(r.data);
    })
  }

  useEffect(() => {
      window.reloadWorkflow = getWorkflowItems
  }, [getWorkflowItems])

  var _cur = useMemo(() => {
    return workflowItems.findIndex(_ => _ === spid);
  }, [workflowItems, spid]);

  const refreshCounts = () => {
    if (summaryData.WS_Outcome !== wsOutcome) {
      getWorkflowItems()
      getWorkflowCounts()
    }
  }

  const getWorkflowNext = () => {
    if (_cur !== workflowItems.length-1) {
      refreshCounts()
      setSpid(workflowItems[_cur+1]);
    }
  }

  const getWorkflowLast = () => {
    if (_cur !== 0) {
      refreshCounts()
      setSpid(workflowItems[_cur-1]);
    }
  }

  const updateOutcome = () => {
    request(true).post(endpoints.BUSINESS_ADDRESS_ALERTS_UPDATE_OUTCOMES, {
      SPID: spid,
      WS_Outcome: wsOutcome,
      Ret_Outcome: retOutcome
    }).then(e => {
      // window.alert(e.data);
    }).catch(e => {
      console.log(e)
      window.alert("Update failed.")
    })
  }

  const submitNotes= (noteType) =>{
    request(true).post(endpoints.BUSINESS_ADDRESS_ALERTS_SUBMIT_NOTES, {
      SPID: spid,
      notes: noteType === "Wholesaler" ? wholesalerNotes : retailerNotes,
      noteType: noteType
    }).then(e => {
      // window.alert(e.data);
      setWsNoteIndex(e.data.filter(note => note.Note_Type === "Wholesaler").length)
      setRetNoteIndex(e.data.filter(note => note.Note_Type === "Retailer").length)
      setPrevWsNotes(e.data.filter(note => note.Note_Type === "Wholesaler"))
      setPrevRetNotes(e.data.filter(note => note.Note_Type === "Retailer"))
      setRetailerNotes('')
      setWholesalerNotes('')
      window.alert("Note submitted")
    }).catch(e => {
      console.log(e)
      window.alert("Update failed.")
    })
  }

  useEffect(() => {
    if (workflow) {
      getWorkflowCounts()
    }
  }, [])

  useEffect(() => {
    if (summaryData.Core_SPID) {
      setMdsDataReady(false)
      getMDSData()
    }
  }, [summaryData])

  useEffect(() => {
    if (retOutcome || wsOutcome) {
      updateOutcome()
    }
  }, [retOutcome, wsOutcome])

  useEffect(() => {
    _cur = 0;
  }, [workflowOutcomeSetting])

  useEffect(() => {
    if (workflow) {
      getWorkflowItems();
    }
  }, [workflowOutcomeSetting])

  useEffect(() => {
    if (spid && spid !== summaryData.Core_SPID) {
      getSummaryData()
      setMdsDataReady(false);
    }
  }, [spid])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.target.id === "wSpid") {
        setMdsDataReady(false);
        getSummaryData(wSpid, "wSpid")
      } else if (event.target.id === "sSpid") {
        setMdsDataReady(false);
        getSummaryData(wSpid, "sSpid")
      } else {
        setWSpid('');
        setSSpid('');
        setSpid(search ? search : spid);
      }
    }
  }

   const outcomes = ["New", "In Progress", "Visit Required", "Deregister", "Complete", "Deregistered", "Dead End", "Reject", "Assured"];

  return (
    <div style={{ display: 'grid', gap: '10px' }}>
      { workflow && 
        <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: `repeat(${outcomes.length}, auto)`, margin: '0em 5em' }}>
          {
            outcomes.map(outcome => {
              const count = workflowCounts.find(wfCount => wfCount?.WS_Outcome === outcome);
              return (
                <button className={`button compact smaller-text ${workflowOutcomeSetting === outcome ? "bulk-buy-button" : "background-primary"} colour-white`} onClick={() => {
                  refreshCounts()
                  setWorkflowOutcomeSetting(outcome)
                }}>
                  {outcome} ({count?.count ?? 0})
                </button>
              )
            })
          }
        </div>
      }
      <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
      {/* <input type='text' /> */}
        <col width={180} />
        <thead>
          <tr>
            <th colSpan={10}>{translations['HP.Header_Summary']}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width={400}>{translations['HP.Core_SPID']}</td>
            <td colSpan={generic ? 5 : 2}>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input style={{border: 'none'}} type="text" onChange={e => setSearch(e.target.value)} value={loadingWorkflow ? '' : search ? search : spid} onKeyDown={handleKeyDown} />
                { loadingWorkflow && <i className="fa fa-spinner fa-spin" style={{ position: 'absolute', paddingLeft: '2px' }}/> }
                {
                  workflow ? <>
                    <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowLast}></span>
                    <span>{_cur+1}/{workflowItems.length}</span>
                    <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowNext}></span>
                  </> : <>
                  </>
                }
                <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => setSpid(search)}></span>
              </div>
            </td>
            { generic !== true &&
              <>
                <td>{translations['HP.Match_Category']}</td>
                <td colSpan={'2'}>
                  {summaryData['Address_Status']}
                </td>
              </>
            }
            <td>{translations['HP.Workflow_Status']}</td>
            <td colSpan={3}>
              <select disabled={!hasPermission('BUSINESS_ADDRESS_ALERTS', 'WHOLESALER') || generic} value={wsOutcome ? wsOutcome : summaryData.WS_Outcome ?? 'New'} onChange={(event) => setWsOutcome(event.target.value)} style={{paddingLeft: 0}}>
                <option value="New">New</option>
                <option value="In Progress">In Progress</option>
                <option value="Visit Required">Visit Required</option>
                <option value="Deregister">Deregister</option>
                <option value="Complete">Complete</option>
                <option value="Deregistered">Deregistered</option>
                <option value="Dead End">Dead End</option>
                <option value="Reject">Reject</option>
                <option value="Assured">Assured</option>
              </select>
            </td>
          </tr>
          <tr>
            <td width='(100/4)%'>{translations['HP.Water_SPID']}</td>
            <td width='(100/4)%'>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input id={"wSpid"} style={{border: 'none', backgroundColor: 'unset'}} type="text" onChange={e => setWSpid(e.target.value)} value={wSpid ? wSpid : summaryData['W_SPID'] ?? ''} disabled={generic} onKeyDown={!generic && handleKeyDown} />
                { !generic && 
                  <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => {
                    setSearch('')
                    setSSpid('')
                    getSummaryData(wSpid, "wSpid")}
                  }></span>
                }
              </div>
            </td>
            <td width='(100/4)%'>{translations['HP.Water_Wholesaler']}</td>
            <td width='(100/4)%'>{summaryData['W_Wholesaler_ID']}</td>
            <td width='(100/4)%'>{translations['HP.Water_Retailer']}</td>
            <td width='(100/4)%'>{summaryData['W_Retailer_ID']}</td>
            { generic === true ?
              <td colSpan={4} rowSpan={11} style={{ alignContent: 'start', backgroundColor: 'var(--background)', padding: '0px' }}>
                <table className='table borders squish smaller-text left' style={{ height: '100%' }}>
                  <thead style={{ border: '1px solid var(--primary)' }}>
                    <tr>
                      <th style={{ borderRadius: '0px', border: '1px, solid, var(--primary)', width: '36%' }}>Module</th>
                      <th style={{ border: '1px, solid, var(--primary)' }}>Issue Type</th>
                      <th style={{ border: '1px, solid, var(--primary)' }}>Issue</th>
                      <th style={{ borderRadius: '0px', border: '1px, solid, var(--primary)' }}>Link</th>
                    </tr>
                  </thead>
                    <tbody>
                      { searchResults?.map(result => {
                        const key = Object.keys(result)[0]
                        var issue
                        var issueType
                        issue = Object.values(result[key]).filter(key => key !== "").join(' & ')
                        issueType = Object.keys(result[key]).map(key=> key.replaceAll('_', ' ')).join(' & ')
                        return (
                          <tr>
                            <td>
                              <input disabled={true} value={key} style={{border: 'none', backgroundColor: 'unset'}} type="text" />
                            </td>
                            <td>{issueType}</td>
                            <td>{issue}</td>
                            <td><Link to={{ pathname: links[key], state: { spid: spid } }}>To Module</Link></td>
                          </tr>
                        )
                      })}
                      {
                        searchIsLoading && 
                        <tr>
                          <td colSpan={4}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <i className="fa fa-spinner fa-spin"></i>
                            </div>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
              </td>
              :
                <td colSpan={4} rowSpan={11} style={{height: '100%'}}>
                  <div style={{position: 'relative'}}>
                    <textarea disabled={!hasPermission('BUSINESS_ADDRESS_ALERTS', 'WHOLESALER')} onChange={(event) => setWholesalerNotes(event.target.value.slice(0, 500))} value={wholesalerNotes ?? ''} rows={8} style={{height: '100%', width: '100%', resize: 'none'}}/>
                    <div style={{ position: 'relative', bottom: '3px', float: 'right', color: 'gray', fontSize: 12 }}>
                      {wholesalerNotes.length}/500
                    </div>
                    <textarea disabled={true} value={prevWsNotes[wsNoteIndex - 1]?.Note ?? ''} rows={8} style={{height: '100%', width: '100%', resize: 'none'}}/>
                    <div style={{ position: 'relative', bottom: '3px', float: 'right', color: 'gray', fontSize: 12, display: 'flex', gap: 10, alignItems: 'center' }}>
                      {wsNoteIndex}/{prevWsNotes.length} <i class="fa-solid fa-chevron-left" style={{ cursor: wsNoteIndex !== 1 && wsNoteIndex !== 0 ? 'pointer' : 'unset' }} onClick={() => setWsNoteIndex(wsNoteIndex !== 1 && wsNoteIndex !== 0 ? wsNoteIndex - 1 : wsNoteIndex)} /> <i class="fa-solid fa-chevron-right" style={{ cursor: wsNoteIndex !== prevWsNotes.length ? 'pointer' : 'unset' }} onClick={() => setWsNoteIndex(wsNoteIndex !== prevWsNotes.length ? wsNoteIndex + 1 : wsNoteIndex)} />
                    </div>
                    <div style={{ position: 'relative', bottom: 5, left: 5, color: 'gray', fontSize: 12, display: 'flex', gap: 10, alignItems: 'center', minHeight: '3vh' }}>
                      { prevWsNotes.length > 0 ?
                          prevWsNotes[wsNoteIndex - 1]?.User_Name + " - " + 
                          new Date(prevWsNotes[wsNoteIndex - 1]?.Date).toLocaleString('en-GB', { timeZone: 'Europe/London', hour12: false }).replace(',', '').replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')
                      : ''}
                    </div>
                  </div>
                  <button 
                    disabled={!hasPermission('BUSINESS_ADDRESS_ALERTS', 'WHOLESALER') || wholesalerNotes.length < 0} 
                    className={`button compact smaller-text ${hasPermission('BUSINESS_ADDRESS_ALERTS', 'WHOLESALER') && wholesalerNotes.length > 0 ? 'background-primary colour-white' : ''}`}
                    onClick={() => submitNotes('Wholesaler')}
                  >Submit Notes</button>
                </td>
            }
          </tr>
          <tr>
            <td>{translations['HP.Sewerage_SPID']}</td>
            <td>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input id={"sSpid"} style={{border: 'none'}} type="text" onChange={e => setSSpid(e.target.value)} value={sSpid ? sSpid : summaryData['S_SPID'] ?? ''} disabled={generic} onKeyDown={handleKeyDown} />
                { !generic && 
                  <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => {
                    setSearch('')
                    setWSpid('')
                    getSummaryData(sSpid, "sSpid")}
                  }></span>
                }
              </div>
            </td>
            <td>{translations['HP.Sewerage_Wholesaler']}</td>
            <td>{summaryData['S_Wholesaler_ID']}</td>
            <td>{translations['HP.Sewerage_Retailer']}</td>
            <td>{summaryData['S_Retailer_ID']}</td>
          </tr>
          <tr>
            <td>{translations['HP.CMOS_UPRN']}</td>
            <td width='(100/4)%'>{summaryData['CMOS_UPRN']}</td>
            <td width='(100/4)%'>{translations['HP.Matched_UPRN']}</td>
            <td width='(100/4)%'>{summaryData['Matched_UPRN']}</td>
            <td width='(100/4)%'>{translations['HP.Status_UPRN']}</td>
            <td width='(100/4)%'>{summaryData['Status_UPRN']}</td>
          </tr>
          <tr>
            <td>{translations['HP.UPRN_WSPID_Reason_Code']}</td>
            <td width='(100/4)%'>{summaryData['W_UPRN_Reason_Code']}</td>
          </tr>
          <tr>
            <td>{translations['HP.CMOS_VOA_No']}</td>
            <td width='(100/4)%'>{summaryData['CMOS_VOA_No']}</td>
            <td width='(100/4)%'>{translations['HP.Matched_VOA_No']}</td>
            <td width='(100/4)%'>{summaryData['Matched_VOA_No']}</td>
            <td width='(100/4)%'>{translations['HP.Status_VOA_No']}</td>
            <td width='(100/4)%'>{summaryData['Status_VOA_No']}</td>
          </tr>
          <tr>
            <td>{translations['HP.VOA_WSPID_Reason_Code']}</td>
            <td width='(100/4)%'>{summaryData['W_VOA_BA_Reference_Reason_Code']}</td>
          </tr>
          <tr>
            <td>{translations['HP.CMOS_Address_Single_Line']}</td>
            <td colSpan={'3'}>{summaryData['CMOS_Address_Single_Line']}</td>
            <td>{translations['HP.UPRN_GISX']}</td>
            <td>{summaryData['CMOS_UPRN_ABP_GISX']}</td>
          </tr>
          <tr>
            <td>{translations['HP.CMOS_UPRN_Single_Line']}</td>
            <td colSpan={'3'}>{summaryData['CMOS_UPRN_Single_Line']}</td>
            <td>{translations['HP.UPRN_GISY']}</td>
            <td>{summaryData['CMOS_UPRN_ABP_GISY']}</td>
          </tr>
          <tr>
            <td>{translations['HP.Matched_UPRN_Single_Line']}</td>
            <td colSpan={'3'}>{summaryData['Matched_UPRN_Single_Line']}</td>
            <td>{translations['HP.Meter_GISX']}</td>
            <td>{summaryData['GISX']}</td>
          </tr>
          <tr>
            <td>{translations['HP.CMOS_VOA_Single_Line']}</td>
            <td colSpan={'3'}>{summaryData['CMOS_VOA_Single_Line']}</td>
            <td>{translations['HP.Meter_GISY']}</td>
            <td>{summaryData['GISY']}</td>
          </tr>
          <tr>
            <td>{translations['HP.Matched_VOA_Single_Line']}</td>
            <td colSpan={'3'}>{summaryData['Matched_VOA_Single_Line']}</td>
            <td>{translations['HP.Occupancy']}</td>
            <td>{summaryData['Occupancy']}</td>
          </tr>
          <tr>
            <td>{translations['HP.Meter_Address']}</td>
            <td colSpan={'3'}>{summaryData['Meter_Address_Single_Line']}</td>
            <td>{translations['HP.Meter_Date']}</td>
            <td>{summaryData['Meter_Read_Date_Curr']}</td>
            <td>{translations['HP.Meter_Type']}</td>
            <td colSpan={'1'}>{summaryData['Meter_Read_Method_Curr']}</td>
            <td>{translations['HP.LUM']}</td>
            <td>{summaryData['LUM']}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const CMOSLive = ({ translations, summaryData: data }) => {
  const rows = useMemo(() => {
    return [
      [translations['HP.CL_VOA_BA_Reference'], 'CL_VOA_BA_Reference'],
      [translations['HP.CL_UPRN'], 'CL_UPRN'],
      [translations['HP.CL_PAF_Address_Key'], 'CL_PAF_Address_Key'],
      [translations['HP.CL_Free_Descriptor'], 'CL_Free_Descriptor'],
      [translations['HP.CL_Secondary_Addressable_Object'], 'CL_Secondary_Addressable_Object'],
      [translations['HP.CL_Primary_Addressable_Object'], 'CL_Primary_Addressable_Object'],
      [translations['HP.CL_Address_Line_1'], 'CL_Address_Line_1'],
      [translations['HP.CL_Address_Line_2'], 'CL_Address_Line_2'],
      [translations['HP.CL_Address_Line_3'], 'CL_Address_Line_3'],
      [translations['HP.CL_Address_Line_4'], 'CL_Address_Line_4'],
      [translations['HP.CL_Address_Line_5'], 'CL_Address_Line_5'],
      [translations['HP.CL_Postcode'], 'CL_Postcode'],
    ]
  }, [translations])
  return (
    <div>
      <GenericTable style={{height: '100%'}} biggerSecondRow={false} firstColWidth={150} title={translations['HP.Header_CmosLive']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={data} />
    </div>
  )
}

export const AddressBase = ({ translations, summaryData: data, selectedMatches, setSelectedMatches, generic=false }) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses.length) return
    setIndex(index + change)
  }

  const getData = (customInput) => {
    if (!customInput && !customPostcode && (!data.ABP_Postcode || data.Address_Status === 'Invalid Postcode') && data.CL_Postcode) {
        setLoading(false);
        return;
    }
    setAddresses([]);
    setIndex(0)
    request(true)
      .get(endpoints.BUSINESS_ADDRESS_ALERTS_GET_ADDRESSES, {
        doesCancel: true,
        params: {
          postcode: customInput || customPostcode || data.ABP_Postcode || data.CL_Postcode
        }
      })
      .then(e => {
        const _data = e.data;
        setAddresses(_data);
        if (data.CF_UPRN) {
          const displayFirst = _data.findIndex(_ => _.UPRN === parseInt(data.CF_UPRN))
          setIndex(displayFirst)
          setSelectedMatches((prevMatches) => {
            return {
              ...prevMatches,
              abp: data.CF_UPRN.toString()
            };
          });
        }
        setLoading(false)
      })
      .catch(_ => { window.alert('Could not get Address Base data.'); setAddresses([]); setLoading(false) });
  };

  useEffect(() => {
    setAddresses([])
    setIndex(-1)
    if (data.Core_SPID) {
      setIndex(-1)
      setCustomPostcode('');
      setLoading(true)
      getData(data.ABP_Postcode || data.CL_Postcode)
    }
  }, [data.Core_SPID]);

  useEffect(() => {
    if (customPostcode !== '') return
    if (!data.Core_SPID) {
      setLoading(false)
      setAddresses([])
    } else {
      getData();
    }
  }, [customPostcode])

  const originalPostcode = useMemo(() => {
    return data.ABP_Postcode || data.CL_Postcode;
  }, [data.ABP_Postcode, data.CL_Postcode]);

  const selectMatch = useCallback(() => {
    if (!addresses[index]?.UPRN) return
    if (selectedMatches.abp == addresses[index].UPRN) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          abp: ''
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          abp: typeof addresses[index].UPRN === "number" ? addresses[index].UPRN.toString() : addresses[index].UPRN
        };
      });
    }
  }, [addresses, selectedMatches, setSelectedMatches, index])

  return (
    <div>
      <table className='table borders squish smaller-text left' style={{height: '100%'}}>
        <thead>
          <tr>
            <th style={{ textAlign: 'start' }} colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {translations['HP.Header_AddressBase']}
                <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                  {customPostcode && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span className='fas fa-trash-undo' onClick={() => { setCustomPostcode(''); setLoading(true) }} title="Restore original postcode search"></span>
                    </div>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span className='fas fa-search' onClick={() => setPostcodeModal(true)} title="Search for a different postcode"></span>
                  </div>
                  {
                    addresses.length ? <>
                      <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span className='fas fa-angle-left' onClick={() => cycleIndex(-1)}></span>
                        <span style={{minWidth: 60, textAlign: 'center'}}>{index+1}/{addresses.length}</span>
                        <span className='fas fa-angle-right' onClick={() => cycleIndex(1)}></span>
                      </span>
                    </> : null
                  }
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{translations['HP.UPRN']}</td>
            <td style={{minWidth: '60px', textAlign: loading ? 'center' : 'unset'}}>
              { 
                loading ?
                  <i className="fa fa-spinner fa-spin"></i>
                  :
                  addresses[index]?.UPRN ?? '' 
              }</td>
          </tr>
          <tr>
            <td>{translations['HP.CLASSIFICATION_CODE']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CLASSIFICATION_CODE ? classCodes.find(classCode => classCode.Concatenated === addresses[index]?.CLASSIFICATION_CODE)?.Class_Desc : '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.UDPRN']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.UDPRN ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.ORGANISATION']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.ORGANISATION ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.SUB_BUILDING']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.SUB_BUILDING ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.BUILDING_NAME']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.BUILDING_NAME ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.BUILDING_NUMBER']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.BUILDING_NUMBER ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.STREET_NAME']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.STREET_NAME ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.LOCALITY']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.LOCALITY ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.TOWN_NAME']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.TOWN_NAME ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.POST_TOWN']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.POST_TOWN ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.Postcode']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.POSTCODE ?? '' }</td>
          </tr>
          <tr>
            <td style={{minWidth: '60px'}}><button disabled={generic} className={`button compact smaller-text ${data.CF_UPRN && !generic ? 'cancel colour-white' : ''}`} onClick={() => setClearMatchModal(data.CF_UPRN ? true : false) }>Clear Match</button></td>
            <td style={{minWidth: '60px'}}><button disabled={generic} className={`button compact smaller-text ${selectedMatches.abp && selectedMatches.abp == addresses[index]?.UPRN && !generic ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button></td>
          </tr>
        </tbody>
      </table>
      <ReactModal
        isOpen={postcodeModal}
        onRequestClose={() => setPostcodeModal(false)}
        className="card bulk-allocate"
        contentLabel="Bulk Allocate COT Alert Data"
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>Postcode Search</h3>
          <input value={customPostcode ? customPostcode : originalPostcode} onChange={_ => setCustomPostcode(_.target.value.toUpperCase())} style={{ border: '1px solid gray', width: '100%', borderRadius: 3 }} />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <button className={`button compact smaller-text ${customPostcode?.length >= 3 ? 'background-primary colour-white' : ''}`} disabled={customPostcode?.length < 3} onClick={() => { getData(customPostcode); setPostcodeModal(false); setLoading(true) }}>Search</button>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={clearMatchModal}
        onRequestClose={() => setClearMatchModal(false)}
        className="card bulk-allocate"
        contentLabel="Bulk Allocate COT Alert Data"
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>Clear existing match</h3>
          <p>Please confirm you wish to clear the following matched UPRN for ABP on this SPID.</p>
          <ul className='spid-list'>
            <li>SPID: {data.Core_SPID}</li>
            <li>ABP UPRN: {data.CF_UPRN}</li>
          </ul>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
            <button className="button compact smaller-text background-primary colour-white" onClick={() => {
              request(true).post(endpoints.BUSINESS_ADDRESS_ALERTS_CLEAR_MATCH, {
                spid: data.Core_SPID,
                matchType: 'abp'
              }).then(r => {
                window.reloadSummaryData()
                setClearMatchModal(false); 
              })
            }}
            >Confirm</button> 
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export const ValuationOffice = ({ translations, summaryData: data, selectedMatches, setSelectedMatches, generic=false }) => {
  const [loading, setLoading] = useState(false);
  const [voas, setVoas] = useState([])
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= voas.length) return
    setIndex(index + change)
  }

  const getData = (customInput) => {
    if (!customInput && !customPostcode && (!data.ABP_Postcode || data.Address_Status === 'Invalid Postcode') && data.CL_Postcode) {
        setLoading(false);
        return;
    }
    setVoas([])
    setIndex(0)
    request(true).get(data.Wholesaler === "SCOTTISH-W" ? endpoints.BUSINESS_ADDRESS_ALERTS_GET_SAA : endpoints.BUSINESS_ADDRESS_ALERTS_GET_VOAS, {
      doesCancel: true,
      params: {
        postcode: customInput || customPostcode || data.VO_Postcode || data.CL_Postcode
      }
    }).then(e => {
      const _data = e.data
      setVoas(_data);
      if (data.Matched_VOA_No) {
        const displayFirst = _data.findIndex(_ => _.BA_Reference_Number === data.Matched_VOA_No && _.Postcode === data.CL_Postcode)
        setIndex(displayFirst);
        setSelectedMatches((prevMatches) => {
          return {
            ...prevMatches,
            voa: typeof data.Matched_VOA_No === "number" ? data.Matched_VOA_No.toString() : data.Matched_VOA_No
          };
        });
      }
      setLoading(false)
    }).catch(() => {
      window.alert('Could not get Valuation Office data');
      setVoas([])
      setLoading(false)
    })
  }

  useEffect(() => {
    setVoas([])
    setIndex(-1)
    if (data.Core_SPID) {
      setIndex(-1)
      setCustomPostcode('')
      setLoading(true)
      getData(data.ABP_Postcode || data.CL_Postcode)
    }
  }, [data.Core_SPID])

  // const selectedVOA = useMemo(() => {
  //   const selected = voas.length > -1 ? voas[index] : null;
  //   if (!selected ) return {}
  //   selected.Source = data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? 'SAA' : 'Business Rates'
  //   selected.Deletion_Date = selected.Effective_Status === 'DELETED' ? selected.Current_From_Date : ''
  //   setPostcode(selected.Postcode)
  //   return selected
  // }, [voas, index, data, setPostcode]);

  useEffect(() => {
    if (customPostcode !== '') return 
    if (!data.Core_SPID) {
      setVoas([])
      setLoading(false)
    } else {
      getData();
    }
  }, [customPostcode])

  const selectMatch = useCallback(() => {
    if (!voas[index]?.BA_Reference_Number) return
    if (selectedMatches.voa == voas[index].BA_Reference_Number) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          voa: ''
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          voa: typeof voas[index].BA_Reference_Number === "number" ? voas[index].BA_Reference_Number.toString() : voas[index].BA_Reference_Number
        };
      });
    }
  }, [voas, selectedMatches, setSelectedMatches, index])

  const originalPostcode = useMemo(() => {
    return data.VO_Postcode || data.CL_Postcode
  }, [data.ABP_Postcode, data.CL_Postcode])

  return [
    <table className='table borders squish smaller-text left' style={{height: '100%'}}>
      <thead>
        <tr>
          <th style={{ textAlign: 'start' }} colSpan={2}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {translations['HP.Header_Valuation']}
              <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                {customPostcode && (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span className='fas fa-trash-undo' onClick={() => { setCustomPostcode(''); setLoading(true); }} title="Restore original postcode search"></span>
                  </div>
                )}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <span className='fas fa-search' onClick={() => setPostcodeModal(true)} title="Search for a different postcode"></span>
                </div>
                {
                  voas.length ? <>
                    <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <span className='fas fa-angle-left' onClick={() => cycleIndex(-1)}></span>
                      <span style={{minWidth: 60, textAlign: 'center'}}>{index+1}/{voas.length}</span>
                      <span className='fas fa-angle-right' onClick={() => cycleIndex(1)}></span>
                    </span>
                  </> : null
                }
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{translations['HP.BA_Reference_Number']}</td>
          <td style={{minWidth: '60px', textAlign: loading ? 'center' : 'unset'}}>
            { 
              loading ? 
                <i className="fa fa-spinner fa-spin"></i>
                :
                voas[index]?.BA_Reference_Number ?? '' 
            }
          </td>
        </tr>
        <tr>
          <td>{translations['HP.Primary_Description_Text']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.DESCRIP?? '' : voas[index]?.Primary_Description_Text ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['HP.Source']}</td>
          <td style={{minWidth: '60px'}}>{ voas[index]?.Source ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['HP.Number_Or_Name']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.PAON ?? '' : voas[index]?.Number_Or_Name ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['HP.Street']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.STREET ?? '' : voas[index]?.Street ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['HP.Sub_Street_level_1']}</td>
          <td style={{minWidth: '60px'}}>{ voas[index]?.Sub_Street_level_1 ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['HP.Town']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.TOWN ?? '' : voas[index]?.Town ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['HP.Postal_District']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.ADMIN_AREA ?? '' : voas[index]?.Postal_District ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['HP.County']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.UNITARY_AUTHORITY ?? '' : voas[index]?.County ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['HP.Postcode']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.BR_Postcode ?? '' : voas[index]?.Postcode ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['HP.Effective_Status']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.OCC_STATUS ?? '' : voas[index]?.Effective_Status ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['HP.Deletion_Date']}</td>
          <td style={{minWidth: '60px'}}>{ voas[index]?.Deletion_Date ?? '' }</td>
        </tr>
        <tr>
          <td style={{minWidth: '60px'}}><button disabled={generic} className={`button compact smaller-text ${data.Matched_VOA_No && !generic ? 'cancel colour-white' : ''}`} onClick={() => setClearMatchModal(data.Matched_VOA_No ? true : false) }>Clear Match</button></td>
          <td style={{minWidth: '60px'}}><button disabled={generic} className={`button compact smaller-text ${selectedMatches.voa && selectedMatches.voa == voas[index]?.BA_Reference_Number && !generic ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button></td>
        </tr>
      </tbody>
    </table>,
    <ReactModal 
    isOpen={postcodeModal} 
    onRequestClose={() => setPostcodeModal(false)}
    className="card bulk-allocate"
    contentLabel="Bulk Allocate COT Alert Data"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Postcode Search</h3>
        <input value={customPostcode} onChange={_ => setCustomPostcode(_.target.value)} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <button className={`button compact smaller-text ${customPostcode?.length >= 3 ? 'background-primary colour-white' : ''}`} disabled={customPostcode?.length < 3} onClick={() => {getData(customPostcode || undefined); setLoading(true); setPostcodeModal(false)}}>Search</button> 
        </div>
      </div>
    </ReactModal>,
    <ReactModal
      isOpen={clearMatchModal}
      onRequestClose={() => setClearMatchModal(false)}
      className="card bulk-allocate"
      contentLabel="Bulk Allocate COT Alert Data"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <h3>Clear existing match</h3>
        <p>Please confirm you wish to clear the following matched Reference for VOA on this SPID.</p>
        <ul className='spid-list'>
          <li>SPID: {data.Core_SPID}</li>
          <li>VOA Reference: {data.Matched_VOA_No}</li>
        </ul>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
          <button className="button compact smaller-text background-primary colour-white" onClick={() => {
            request(true).post(endpoints.BUSINESS_ADDRESS_ALERTS_CLEAR_MATCH, {
              spid: data.Core_SPID,
              matchType: 'voa'
            }).then(r => {
              window.reloadSummaryData()
              setClearMatchModal(false); 
            })
          }}
          >Confirm</button> 
        </div>
      </div>
    </ReactModal>
  ]
}

export const CouncilTax = ({ translations, summaryData: data, selectedMatches, setSelectedMatches, generic=false }) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses.length) return
    setIndex(index + change)
  }

  const getData = (customInput) => {
    if (!customInput && !customPostcode && (!data.ABP_Postcode || data.Address_Status === 'Invalid Postcode') && data.CL_Postcode) {
        setLoading(false);
        return;
    }
    setAddresses([])
    setIndex(0)
    request(true).get(endpoints.BUSINESS_ADDRESS_ALERTS_GET_CT, {
      doesCancel: true,
      params: {
        postcode: customInput || data.ABP_Postcode || data.CL_Postcode
      }
    })
    .then(e => {
      const _data = e.data;
      setAddresses(_data)
      if (data.au_reference_number) {
        const displayFirst = _data.findIndex(_ => _.au_reference_number === data.au_reference_number) 
        setIndex(displayFirst);
        setSelectedMatches((prevMatches) => {
          return {
            ...prevMatches,
            ct: typeof data.au_reference_number === "nummber" ? data.au_reference_number.toString() : data.au_reference_number 
          };
        });
      }
      setLoading(false)
    })
    .catch(_ => { window.alert('Could not get Council Tax data.'); setAddresses([]); setLoading(false) })
  };

  useEffect(() => {
    setAddresses([])
    setIndex(-1)
    if (data.Core_SPID) {
      setIndex(-1)
      setCustomPostcode('')
      setLoading(true)
      getData(data.ABP_Postcode || data.CL_Postcode)
    }
  }, [data.Core_SPID])

  const tableData = useMemo(() => {
    const selected = addresses.length > -1 ?  addresses[index] : null;
    if (!selected ) return {}
    selected.STATUS = selected.CHANGE_CODE === 'D' ? 'Deleted' : 'Active';
    // setCustomPostcode(selected.Postcode)
    if (selected.address) {
      const addressLines = parseAddress(selected.address)
      addressLines.forEach((line, index) => {
        selected['CT_Address_Line_' + (index + 1)] = line
      })
    }
    return selected
  }, [addresses, index]);

  const originalPostcode = useMemo(() => {
    return data.VO_Postcode || data.CL_Postcode
  }, [data.ABP_Postcode, data.CL_Postcode])

  const selectMatch = useCallback(() => {
    if (!addresses[index]?.au_reference_number) return
    if (selectedMatches.ct == addresses[index].au_reference_number) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          ct: ''
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          ct: typeof addresses[index].au_reference_number === "number" ? addresses[index].au_reference_number.toString() : addresses[index].au_reference_number
        };
      });
    }
  }, [addresses, selectedMatches, setSelectedMatches, index])

  function parseAddress(address) {

    const addressLines = address.split(", ");

    return addressLines;
}

  const rows = useMemo(() => {
    return [
      [translations['HP.CT_Reference'], 'au_reference_number'],
      [translations['HP.CT_Band'], 'council_tax_band'],
      [translations['HP.CT_Mixed_Use_Property'], 'mixed_use_property'],
      [translations['HP.CF_Free_Descriptor'], 'CT_Free_Descriptor'],
      [translations['HP.CF_Secondary_Addressable_Object'], 'CT_Secondary_Addressable_Object'],
      [translations['HP.CF_Primary_Addressable_Object'], 'CT_Primary_Addressable_Object'],
      [translations['HP.CF_Address_Line_1'], 'CT_Address_Line_1'],
      [translations['HP.CF_Address_Line_2'], 'CT_Address_Line_2'],
      [translations['HP.CF_Address_Line_3'], 'CT_Address_Line_3'],
      [translations['HP.CF_Address_Line_4'], 'CT_Address_Line_4'],
      [translations['HP.CT_Band'], 'council_tax_band'],
      [translations['HP.CF_Postcode'], 'postcode'],
      ['', () => <button className={`button compact smaller-text ${selectedMatches.ct && selectedMatches.ct === addresses[index?.au_reference_number] ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button>]
    ]
  }, [translations, window.reloadSummaryData, index])
  return (
    <div>
      <table className='table borders squish smaller-text left' style={{height: '100%'}}>
        <thead>
          <tr>
            <th style={{ textAlign: 'start' }} colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {translations['HP.Council_Tax']}
                <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                  {customPostcode && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span className='fas fa-trash-undo' onClick={() => { setCustomPostcode(''); getData(); setLoading(true); }} title="Restore original postcode search"></span>
                    </div>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span className='fas fa-search' onClick={() => setPostcodeModal(true)} title="Search for a different postcode"></span>
                  </div>
                  {
                    addresses.length ? <>
                      <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span className='fas fa-angle-left' onClick={() => cycleIndex(-1)}></span>
                        <span style={{minWidth: 60, textAlign: 'center'}}>{index+1}/{addresses.length}</span>
                        <span className='fas fa-angle-right' onClick={() => cycleIndex(1)}></span>
                      </span>
                    </> : null
                  }
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{translations['HP.CT_Reference']}</td>
            <td style={{minWidth: '60px', textAlign: loading ? 'center' : 'unset'}}>
              {
                loading ? 
                  <i className="fa fa-spinner fa-spin"></i>
                  :
                  addresses[index]?.au_reference_number ?? '' 
              }
            </td>
          </tr>
          <tr>
            <td>{translations['HP.CT_Band']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.council_tax_band ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.CT_Mixed_Use_Property']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.mixed_use_property ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.CF_Free_Descriptor']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Free_Descriptor ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.CF_Secondary_Addressable_Object']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Secondary_Addressable_Object ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.CF_Primary_Addressable_Object']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Primary_Addressable_Object ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.CF_Address_Line_1']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Address_Line_1 ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.CF_Address_Line_2']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Address_Line_2 ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.CF_Address_Line_3']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Address_Line_3 ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.CF_Address_Line_4']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Address_Line_4 ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.CT_Band']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.council_tax_band ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.CF_Postcode']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.postcode ?? '' }</td>
          </tr>
          <tr>
            <td style={{minWidth: '60px'}}><button disabled={generic} className={`button compact smaller-text ${data.au_reference_number && !generic ? 'cancel colour-white' : ''}`} onClick={() => setClearMatchModal(data.au_reference_number ? true : false) }>Clear Match</button></td>
            <td style={{minWidth: '60px'}}><button disabled={generic} className={`button compact smaller-text ${selectedMatches.ct && selectedMatches.ct == addresses[index]?.au_reference_number && !generic ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button></td>
          </tr>
        </tbody>
      </table>
      <ReactModal 
      isOpen={postcodeModal} 
      onRequestClose={() => setPostcodeModal(false)}
      className="card bulk-allocate"
      contentLabel="Bulk Allocate COT Alert Data"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>Postcode Search</h3>
          <input value={customPostcode} onChange={_ => setCustomPostcode(_.target.value)} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <button className={`button compact smaller-text ${customPostcode?.length >= 3 ? 'background-primary colour-white' : ''}`} disabled={customPostcode?.length < 3} onClick={() => {getData(customPostcode || undefined); setLoading(true); setPostcodeModal(false)}}>Search</button> 
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={clearMatchModal}
        onRequestClose={() => setClearMatchModal(false)}
        className="card bulk-allocate"
        contentLabel="Bulk Allocate COT Alert Data"
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>Clear existing match</h3>
          <p>Please confirm you wish to clear the following matched Reference for Council Tax on this SPID.</p>
          <ul className='spid-list'>
            <li>SPID: {data.Core_SPID}</li>
            <li>CT Reference: {data.au_reference_number}</li>
          </ul>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
            <button className="button compact smaller-text background-primary colour-white" onClick={() => {
              request(true).post(endpoints.BUSINESS_ADDRESS_ALERTS_CLEAR_MATCH, {
                spid: data.Core_SPID,
                matchType: 'ct'
              }).then(r => {
                window.reloadSummaryData()
                setClearMatchModal(false); 
              })
            }}
            >Confirm</button> 
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

export const CMOSFormatted = ({ translations, summaryData, setSummaryData, selectedMatches, workflow, setConfirmModal, generic=false }) => {
  const updateAddress = () => {
    request(true).post(endpoints.BUSINESS_ADDRESS_ALERTS_SELECT_MATCH, {
      spid: summaryData.Core_SPID,
      ba_reference: selectedMatches.voa ?? '',
      abp_uprn: selectedMatches.abp ??'',
      ct_reference: selectedMatches.ct ?? ''
    }).then(r => {
      window.reloadSummaryData()
    })
  }
  const rows = useMemo(() => {
    var disabled = true;
    if (summaryData) {
      var abpDifferent = selectedMatches.abp !== '' && selectedMatches.abp !== summaryData.CF_UPRN
      var voaDifferent = selectedMatches.voa !== '' && selectedMatches.voa !== summaryData.CF_VOA_BA_Reference
      var ctDifferent = selectedMatches.ct !== '' && selectedMatches.ct !== summaryData.au_reference_number
      if (abpDifferent || voaDifferent || ctDifferent) {
        disabled = false;
      }
    }

    return [
      [translations['HP.CF_VOA_BA_Reference'], 'CF_VOA_BA_Reference'],
      [translations['HP.CF_UPRN'], 'CF_UPRN'],
      [translations['HP.CF_PAF_Address_Key'], 'CF_PAF_Address_Key'],
      [translations['HP.CT_Reference'], 'au_reference_number'],
      [translations['HP.CF_Secondary_Addressable_Object'], 'CF_Secondary_Addressable_Object'],
      [translations['HP.CF_Primary_Addressable_Object'], 'CF_Primary_Addressable_Object'],
      [translations['HP.CF_Address_Line_1'], 'CF_Address_Line_1'],
      [translations['HP.CF_Address_Line_2'], 'CF_Address_Line_2'],
      [translations['HP.CF_Address_Line_3'], 'CF_Address_Line_3'],
      [translations['HP.CF_Address_Line_4'], 'CF_Address_Line_4'],
      [translations['HP.CT_Band'], 'Council_Tax_Band'],
      [translations['HP.CF_Postcode'], 'CF_Postcode'],
      [({summaryData}) => 
        <button 
          // className='button compact smaller-text' 
          disabled={generic}
          className={`button compact smaller-text ${!disabled && !generic ? 'background-primary colour-white' : ''}`} 
          onClick={() => !disabled && updateAddress()}
        >Update Address</button>,         
        ({}) => 
        <button 
          // className='button compact smaller-text' 
          disabled={generic}
          className={`button compact smaller-text ${!generic && 'background-primary colour-white'}`} 
          onClick={() => setConfirmModal(true)}
        >Confirm</button>],
    ]
  }, [translations, updateAddress])
  // const fields = [
  //   'ABP_Sub_Building',
  //   'ABP_Building_Name',
  //   'ABP_Building_Number',
  //   'ABP_Street_Name',
  //   'ABP_Town_Name',
  //   'ABP_Post_Town',
  //   'ABP_Postcode'
  // ];

  // const addressLines = [
  //   'CF_Address_Line_1',
  //   'CF_Address_Line_2',
  //   'CF_Address_Line_3',
  //   'CF_Address_Line_4',
  //   'CF_Address_Line_5',
  //   'CF_Postcode'
  // ];

  // const components = fields.map(field => summaryData[field]).filter(value => value && value.trim() !== '');

  // components.forEach((component, index) => {
  //   if (index < addressLines.length) {
  //     summaryData[addressLines[index]] = component;
  //   }
  // });

  // for (let i = components.length; i < addressLines.length; i++) {
  //   summaryData[addressLines[i]] = '';
  // }
  return (
    <GenericTable biggerSecondRow={false} firstColWidth={155} title={translations['HP.Header_CmosFormatted']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={summaryData} />
  )
}