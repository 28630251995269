import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select'
import GenericPageContainer from "../GenericPageElements/GenericPageContainer";
import endpoints from "../helpers/endpoints";
import { view } from "@risingstack/react-easy-state";
import request from "../helpers/request";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

export default view(function Map() {
    const [search, setSearch] = useState("");
    const [position, setPosition] = useState(null);
    const [error, setError] = useState("");
    const [map, setMap] = useState(null);
    const [searchType, setSearchType] = useState({label: 'UPRN', value: 'uprn'});
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [loadingSuggestions, setLoadingSuggestions] = useState(false)
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedMarker, setSelectedMarker] = useState();
    const markerRefs = useRef([]);

    const handleSearch = () => {
        setError("");
        setLoading(true)

        var path = searchType.value
        var variable = searchType.value
        var formattedSearch = search
        if (searchType.value === "coordinates") {
            path = "nearest"
            variable = "point"
            formattedSearch = `${search.X},${search.Y}`
        } else if (searchType.value === "address") {
            path = "find"
            variable = "query"
        }

        request().get(`https://api.os.uk/search/places/v1/${path}?key=4qzcELrjDUSMjVoELCVYo5HLeY07D7mr&${variable}=${formattedSearch}&output_srs=WGS84`).then(async response => {
            setLoading(false)
            if (response.data.header.totalresults === 0) {
                setError("No results for that search")
                return
            }
            if (selectedMarker !== null && markerRefs.current?.[selectedMarker]) {
                markerRefs.current?.[selectedMarker].closePopup()
            }
            setSelectedMarker()

            var results = response.data.results

            if (searchType.value === "coordinates") {
                const { POSTCODE, UPRN } = results[0].DPA
                results = await request().get(`https://api.os.uk/search/places/v1/postcode?key=4qzcELrjDUSMjVoELCVYo5HLeY07D7mr&postcode=${POSTCODE}&output_srs=WGS84`).then(response => {
                    return response.data.results.sort((a, b) => {
                        if (a.DPA.UPRN === UPRN) return -1;
                        if (b.DPA.UPRN === UPRN) return 1;
                        return 0;
                    });
                })
            }

            if (searchType.value === "address") {
                const firstResult = results[0]
                results = results.filter(result => result.DPA.POSTCODE === firstResult.DPA.POSTCODE);
            }

            const { LAT, LNG } = results[0].DPA;
            setPosition([LAT, LNG]);
            setResults(results)

            if (map) {
                map.flyTo([LAT + 0.0005, LNG], 16);
            }
        }).catch(error => {
            console.log(error)
            setError("Error retrieving data")
            setLoading(false)
        })
    };

    const handleMarkerClick = (e, index) => {
        if (markerRefs.current[selectedMarker]) {
            markerRefs.current[selectedMarker].setIcon(L.icon({
                iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
                iconSize: [25, 41],
                iconAnchor: [16, 32],
                popupAnchor: [0, -32]
            }));
        }

        markerRefs.current[index].setIcon(L.icon({
            iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
            iconSize: [25, 41],
            iconAnchor: [16, 32],
            popupAnchor: [0, -32]
        }));
        setSelectedMarker(index)
    };

    const createIcon = (result, index) => {
        const customIcon = L.icon({
            iconUrl: selectedMarker === index ? "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png" : "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
            iconSize: [25, 41],
            iconAnchor: [16, 32],
            popupAnchor: [0, -32]
        });
        const { LAT, LNG, X_COORDINATE, Y_COORDINATE, ADDRESS, UPRN } = result.DPA;
        const position = [LAT, LNG]
        const addressParts = ADDRESS.split(',');
        return (
            <Marker 
            position={position} 
            icon={customIcon} 
            ref={el => markerRefs.current[index] = el} 
            key={UPRN}
            eventHandlers={{
                click: (e) => handleMarkerClick(e, index)
            }}
            >
                <Popup 
                offset={[0, -5]}
                autoClose
                >
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        <li style={{ marginBottom: '0.5em' }}>
                            <p style={{ margin: '0px', fontWeight: '500' }}>
                                <strong style={{ color: 'var(--parimary)' }}>UPRN:</strong> {UPRN}
                            </p>
                        </li>
                        <li style={{ marginBottom: '0.5em' }}>
                            <p style={{ margin: '0px', fontWeight: '500' }}>
                                <strong style={{ color: 'var(--parimary)' }}>Address:</strong><br />
                                {addressParts.map((part, index) => (
                                    <span key={index}>
                                        {part}
                                        {index < addressParts.length - 1 && <br />}
                                    </span>
                                ))}
                            </p>
                        </li>
                        <li style={{ marginBottom: '0.5em' }}>
                            <p style={{ margin: '0px', fontWeight: '500' }}>
                                <strong style={{ color: 'var(--parimary)' }}>Easting (X):</strong> {X_COORDINATE}
                            </p>
                        </li>
                        <li style={{ marginBottom: '0.5em' }}>
                            <p style={{ margin: '0px', fontWeight: '500' }}>
                                <strong style={{ color: 'var(--parimary)' }}>Northing (Y):</strong> {Y_COORDINATE}
                            </p>
                        </li>
                    </ul>
                </Popup>
            </Marker>
        )
    }

    useEffect(() => {
        if (markerRefs.current?.[0] && searchType.value !== "postcode") {
            markerRefs.current[0].openPopup();
            markerRefs.current[0].setIcon(L.icon({
                iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
                iconSize: [25, 41],
                iconAnchor: [16, 32],
                popupAnchor: [0, -32]
            }));
            setSelectedMarker(0)
        }
    }, [position, results])

    useEffect(() => {
        setSearch("")
    }, [searchType])

    const getSuggestions = debounce(() => {
        setLoadingSuggestions(true)
        request(true).get(endpoints.UPRN_SEARCH_SUGGESTIONS, {
            doesCancel: true,
            params: {
                search: search
            }
        }).then(r => {
            setSearchSuggestions(r.data);
            setLoadingSuggestions(false)
        }).catch(error => {
            console.log(error)
            setSearchSuggestions([])
            setLoadingSuggestions(false)
        })
    }, 300)

    useEffect(() => {
        if (searchType.value === "address" && search !== "") {
            getSuggestions()
        } else {
            setSearchSuggestions([])
            setLoadingSuggestions(false)
        }
    }, [search])

    function debounce(func, delay) {
        let debounceTimer;
        return function(...args) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func(...args), delay);
        };
    }

    return (
        <GenericPageContainer
        title="UPRN Search"
        titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
        >
            <div className="grid grid-gap-15 uprn-search">
                <div className="grid grid-columns-2 grid-gap-15" style={{ gridTemplateColumns: 'min-content min-content auto' }}>
                    { searchType.value === "coordinates" ?
                        <div className="grid grid-columns-2 grid-gap-15">
                            {['X', 'Y'].map(axis => {
                                return (
                                    <input type="text"
                                    placeholder={axis}
                                    className="data-row-field"
                                    value={search?.[axis]}
                                    onChange={(e) => setSearch({...search, [axis]: e.target.value ?? ''})}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' ) {
                                            handleSearch();
                                        }
                                    }}
                                    disabled={!searchType}
                                    />    
                                )
                            })}
                        </div>
                    : searchType.value === "address" ?
                        <Autocomplete
                            value={search}
                            onChange={(event, newValue) => {
                                setSearch(newValue?.SINGLE_LINE_ADDRESS || '');
                            }}
                            filterOptions={() => searchSuggestions}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="Address-Search"
                            options={searchSuggestions}
                            loading={loadingSuggestions}
                            loadingText="Loading..."
                            getOptionLabel={(option) => {
                                console.log(option)
                                if (option.SINGLE_LINE_ADDRESS) {
                                    return option.SINGLE_LINE_ADDRESS
                                } else {
                                    return search
                                }
                            }}
                            renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <li key={key} {...optionProps}>
                                        {option.SINGLE_LINE_ADDRESS ?? search}
                                    </li>
                                );
                            }}
                            sx={{ width: 305, 
                                "& .MuiOutlinedInput-root": {
                                    padding: 0, paddingRight: 8
                                }
                            }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Enter Address" onChange={(event) => setSearch(event.target.value || '')} 
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' && !document.querySelector('.MuiAutocomplete-popper li')) {
                                            event.preventDefault();
                                            handleSearch();
                                        }
                                    }}
                                />
                            )}
                        />
                    :
                        <input
                            style={{ width: searchType.value === "address" ? '30em' : 'unset' }}
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(searchType?.value === "postcode" ? e.target.value.toUpperCase() : e.target.value)}
                            placeholder={`Enter ${searchType.label}`}
                            className="data-row-field"
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' ) {
                                    handleSearch();
                                }
                            }}
                            disabled={!searchType}
                        />
                    }
                    <Select 
                    styles={{
                        control: (baseStyles) => ({
                        ...baseStyles,
                        minWidth: '15em',
                        }),
                        container: (baseStyles) => ({
                            ...baseStyles,
                            zIndex: '999'
                        })
                    }}
                    placeholder="Search by..." 
                    onChange={(option) => setSearchType(option)} 
                    defaultValue={{label: 'UPRN', value: 'uprn'}}
                    options={[
                        {label: 'UPRN', value: 'uprn'},
                        {label: 'Address', value: 'address'},
                        {label: 'Postcode', value: 'postcode'},
                        {label: 'Coordinates', value: 'coordinates'},
                    ]}
                    />
                    <button disabled={loading} style={{ width: '10em' }} className="button compact smaller-text background-primary colour-white" onClick={handleSearch}>
                        {
                            loading ? 
                                <i className="fa fa-spinner fa-spin"></i>
                            :
                                "Search"
                        }
                    </button>
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}
                <MapContainer 
                    center={[54.5, -2.5]} 
                    zoom={6} 
                    style={{ height: "80vh", width: "100%" }}
                    attributionControl={false}
                    maxZoom={22}
                    ref={setMap}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; OpenStreetMap contributors"
                        maxNativeZoom={19}
                        maxZoom={20}
                    />
                    {position && (
                        results.map((result, index) => {
                            return (
                                createIcon(result, index)
                            )
                        })
                    )}
                </MapContainer>
            </div>
        </GenericPageContainer>
    );
});
